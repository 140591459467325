import React from 'react';
interface Props {
  children: React.ReactNode;
  as?: string;
  className?: string;
}

const H2: React.FC<Props> = ({ children, as = 'h1', className }) => {
  const classes = `text-xl font-display sm:text-2xl md:text-3xl ${className ? className : ''}`;
  return React.createElement(as, { className: classes }, children);
};

export default H2;

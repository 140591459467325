import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import React from 'react';

function MenuDisclosure({ entry, active }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="-m-3 flex items-center p-3 hover:bg-gray-50">
            <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-xl bg-brand-green text-white">
              <entry.icon className="h-6 w-6" aria-hidden="true" />
            </div>
            <div className={`ml-4   ${active === entry.name ? 'text-brand-green-dark' : 'text-brand-gray'}`}>
              {entry.name}
              <ChevronRightIcon className={` ml-3 inline-block h-3 w-3 ${open ? 'rotate-90 transform' : ''}`} />
            </div>
          </Disclosure.Button>

          <Disclosure.Panel className="-m-3 grid grid-cols-1 gap-y-7 bg-gray-100/50 p-6">
            {entry.dropdown.map((item, i) => (
              <Link key={i} to={item.href} className="-m-2 flex items-center p-2 hover:bg-gray-50">
                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-xl bg-brand-green text-white">
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </div>

                <div className={`ml-4 ${active === item.name ? 'text-brand-green-dark' : 'text-brand-gray'}`}>{item.name}</div>
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default MenuDisclosure;

// Packages
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

// Types
import type { Location } from '~types/Gatsby';

// Components
import Footer from './Footer';
import Header from './Header';
import AppleWebAppMeta from './meta/AppleWebAppMeta';
import Facebook from './meta/Facebook';
import Favicons from './meta/Favicons';
import Twitter from './meta/Twitter';
import ErrorBoundry from '../common/ErrorBoundry';
import Scripts from './scripts/Scripts';

interface Props {
  children: React.ReactNode;
  location: Location;
  title: string;
  desc: string;
  imageFacebook?: string;
  imageFacebookWidth?: number;
  imageFacebookHeight?: number;
  imageTwitter?: string;
  ogType?: string;
  canonical?: string;
  disableHeader?: boolean;
  active?: string;
  noIndex?: boolean;
  minimalFooter?: boolean;
  dividerFooter?: boolean;
}

const Default: React.FC<Props> = ({ children, location, title, desc, imageFacebook, imageFacebookWidth, imageFacebookHeight, imageTwitter, ogType, canonical, active, noIndex, disableHeader, minimalFooter, dividerFooter }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              summary
            }
          }
        }
      }
    `
  );

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        {noIndex ? <meta name="robots" content="noindex" /> : <meta name="robots" content="index,follow" />}
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <link rel="canonical" href={`${canonical ? canonical : `${data.site.siteMetadata.siteUrl}${location.pathname}`}`} />
        <meta name="author" content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`} />
      </Helmet>

      {/* Favicon */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        desc={desc && desc}
        siteName={title}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
        ogType={ogType}
      />

      {/* Twitter */}
      <Twitter url={data.site.siteMetadata.siteUrl + location.pathname} title={title} imageTwitter={imageTwitter && imageTwitter} desc={desc && desc} />

      <Scripts />
      <Helmet>
        <body className="bg-gray-50" />
      </Helmet>
      {disableHeader !== true && <Header active={active} className={`bg-brand-green/50 text-white`} />}

      <ErrorBoundry>
        <main>{children}</main>
      </ErrorBoundry>

      <Footer minimalFooter={minimalFooter} dividerFooter={dividerFooter} />
    </>
  );
};

export default Default;

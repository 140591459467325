import { Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  BadgeCheckIcon,
  ChatIcon,
  CheckIcon,
  CollectionIcon,
  HomeIcon,
  DownloadIcon,
  LightBulbIcon,
  LocationMarkerIcon,
  MailIcon,
  MicrophoneIcon,
  PencilIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
  UsersIcon,
  XIcon
} from '@heroicons/react/outline';
import { Link } from 'gatsby';
import React, { Fragment, useState } from 'react';
import Button from '../common/Button';
import MainMenu from './menuItems/MainMenu';
import Disclosure from './menuItems/MenuDisclosure';
import Topbar from './menuItems/Topbar';
import { ClockIcon, DeviceMobileIcon } from '@heroicons/react/solid';

const links = [
  {
    name: 'Präventionskurse',
    dropdown: [
      {
        name: 'Kursübersicht',
        description: 'Alle unsere Kurse in der Übersicht.',
        href: '/kurse/',
        icon: CollectionIcon
      },
      {
        name: 'Autogenes Training',
        description: 'Eigen-Hypnose-Techniken mit vielen positiven Effekten.',
        href: '/kurse/autogenes-training-onlinekurs/',
        icon: CheckIcon
      },
      {
        name: 'Progressive Muskelentspannung',
        description: 'Aktives Entspannungsverfahren, dass sehr einfach zu erlernen ist.',
        href: '/kurse/progressive-muskelentspannung-onlinekurs/',
        icon: CheckIcon
      },
      {
        name: 'Stressmanagement',
        description: 'Strategien für Selbst- und Zeitmanagemen um körperliches, psychisches Wohlbefinden zu födern.',
        href: '/kurse/stressmanagement-onlinekurs/',
        icon: CheckIcon
      },
      {
        name: 'Krankenkassencheck',
        description: 'Lasse Dir bis zu 149,00 € erstatten!',
        href: '/kurse/krankenkasse/',
        icon: BadgeCheckIcon
      },
      {
        name: 'Kursquiz',
        description: 'Welcher Kurs passt zu Dir?',
        href: '/kurse/kursquiz/',
        icon: QuestionMarkCircleIcon
      }
    ],
    icon: LightBulbIcon
  },
  {
    name: 'Onlinekursplan',
    icon: ClockIcon,
    href: '/entspannungszeit/'
  },
  {
    name: 'APP',
    icon: DeviceMobileIcon,
    href: '/app/'
  },
  {
    name: 'Wissen',
    dropdown: [
      {
        name: 'Blog',
        description: 'Hier findest du alles zum Thema entspannter werden und zu Entspannungsübungen.',
        href: '/blog/',
        icon: PencilIcon
      },
      {
        name: 'FAQ',
        description: 'Wir beantworten die häufigsten Fragen zum Kurs Autogenes Training und Progressive Muskelentspannung.',
        href: '/faq/',
        icon: QuestionMarkCircleIcon
      },
      {
        name: 'Podcast',
        description: 'Der Podcast der Entspannungshelden ist dein Hörerlebnis für mehr mentale und psychische Gesundheit.',
        href: '/podcast/',
        icon: MicrophoneIcon
      }
    ],
    icon: LightBulbIcon
  },
  {
    name: 'Kontakt',
    icon: ChatIcon,
    dropdown: [
      {
        name: 'Über uns',
        description: 'Das sind die Entspannungshelden.',
        href: '/ueber-uns/',
        icon: UsersIcon
      },
      {
        name: 'Kontaktformular',
        description: 'Du bist neugierig geworden? Sende uns eine Brieftaube oder schreib uns einfach eine Mail.',
        href: '/kontakt/',
        icon: MailIcon
      },
      {
        name: 'Termin',
        description: 'Vereinbare direkt einen kostenlosen 15 Minuten Entspannungscall.',
        href: '/termin/',
        icon: PhoneIcon
      }
    ]
  }
];

export default function Header({ active, className }) {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <header className={`relative ${className ? className : ''}`}>
      <Topbar />
      <MainMenu active={active} links={links} onClick={() => setMobileMenu(true)} />
      <Transition
        show={mobileMenu}
        as={Fragment}
        enter="duration-500 ease"
        enterFrom="opacity-0 -translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="duration-500 ease"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-4"
      >
        <div className="absolute inset-x-0 top-0 z-50 origin-top-right p-2 transition xl:hidden">
          <div className="relative divide-y divide-brand-green rounded-xl bg-white ring-1 ring-black ring-opacity-5">
            <button
              onClick={() => setMobileMenu(false)}
              className="thover:text-gray-500 absolute right-4 top-6 inline-flex items-center justify-center rounded p-2 text-brand-gray hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-green-dark"
            >
              <span className="sr-only">Menü schließen</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="px-5 pb-6 pt-5">
              <nav className="grid grid-cols-1 gap-7">
                {links.map((entry, i) => {
                  if (entry.dropdown) {
                    return <Disclosure entry={entry} active={active} key={i} />;
                  }

                  return (
                    <Link to={entry.href} className="-m-3 flex items-center p-3 hover:bg-gray-50" key={i}>
                      <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-xl bg-brand-green text-white">
                        <entry.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div className={`ml-4 ${active === entry.name ? 'text-brand-green-dark' : 'text-brand-gray'}`}>{entry.name}</div>
                    </Link>
                  );
                })}
              </nav>
            </div>
            <div className="px-5 py-6">
              <div className="mb-3 text-center">
                <Link to="/termin/" className="text-brand-green-dark hover:underline">
                  Termin
                </Link>
              </div>
              <div>
                <Button href="https://elopage.com/users/sign_in" text="Einloggen" target="_blank" className="w-full" />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  );
}

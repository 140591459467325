import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import React from 'react';
import Container from '../../common/Container';
import Countdown from './Countdown';

function Topbar() {
  return (
    <div className="bg-yellow-200 py-3 text-center text-brand-gray">
      <Container noMargin>
        <Link to="/podcast/">
          <div className="items-centers flex justify-center text-xs sm:text-base">
            Zum Podcast
            <span className="ml-2 inline-flex items-center font-bold md:ml-5">
              <ArrowCircleRightIcon className="inline-block h-4 w-4 md:h-6 md:w-6" />
            </span>
          </div>
        </Link>
      </Container>
    </div>
  );
}

export default Topbar;

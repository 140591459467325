import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  twitterSite?: string;
  twitterCreator?: string;
  url?: string;
  title?: string;
  imageTwitter?: string;
  desc?: string;
}

const Twitter: React.FC<Props> = ({ twitterSite, twitterCreator, url, title, imageTwitter, desc }) => (
  <Helmet>
    <meta name="twitter:card" content="summary" />
    {twitterSite && <meta name="twitter:site" content={twitterSite} />}
    {twitterCreator && <meta name="twitter:creator" content={twitterCreator} />}
    {url && <meta name="twitter:url" content={url} />}
    {title && <meta name="twitter:title" content={title} />}
    {desc && <meta name="twitter:description" content={desc} />}
    {imageTwitter && <meta name="twitter:image" content={imageTwitter} />}
  </Helmet>
);

export default Twitter;

import React from 'react';

interface Props {
  type: string;
  label?: string;
  id: string;
  name: string;
  placeholder: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  classNameContainer?: string;
  required?: boolean;
  autoComplete?: string;
  inputMode?: 'url' | 'none' | 'numeric' | 'decimal' | undefined;
  min?: string;
  max?: string;
  pattern?: string;
  white?: boolean;
}

const Input: React.FC<Props> = ({ type, label, id, name, placeholder, onChange, onKeyDown, value, className, classNameContainer, required = false, autoComplete, inputMode, min, max, pattern, white }) => {
  return (
    <div className={classNameContainer}>
      {label ? (
        <label className={`mb-1 block text-left ${white ? 'text-white' : 'text-black'}`} htmlFor={id}>
          {label}
          {required ? '*' : null}
        </label>
      ) : null}

      <div className="relative">
        <input
          className={`focus:outline-none w-full appearance-none rounded-xl border p-4 leading-tight text-gray-700 shadow ${white ? 'focus:ring-white' : 'focus:ring-brand'} ${className ? className : ''}`}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          required={required}
          min={min}
          max={max}
          pattern={pattern}
          autoComplete={autoComplete}
          inputMode={inputMode}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
        />
      </div>
    </div>
  );
};

export default Input;

import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  url?: string;
  title?: string;
  desc?: string;
  siteName?: string;
  imageFacebook?: string;
  imageFacebookWidth?: number;
  imageFacebookHeight?: number;
  locale?: string;
  ogType?: string;
}

const Facebook: React.FC<Props> = ({ url, title, desc, siteName, imageFacebook, imageFacebookWidth, imageFacebookHeight, locale, ogType }) => (
  <Helmet>
    <meta property="og:type" content={ogType ? ogType : 'website'} />
    {url && <meta property="og:url" content={url} />}
    {title && <meta property="og:title" content={title} />}
    {desc && <meta property="og:description" content={desc} />}
    {siteName && <meta property="og:site_name" content={siteName} />}
    {locale && <meta property="og:locale" content={locale} />}
    {imageFacebook && <meta property="og:image" content={imageFacebook} />}
    {imageFacebookWidth && <meta property="og:image:width" content={imageFacebookWidth.toString()} />}
    {imageFacebookHeight && <meta property="og:image:height" content={imageFacebookHeight.toString()} />}
  </Helmet>
);

export default Facebook;

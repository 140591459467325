import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import Button from '../common/Button';
import Input from '../forms/elements/Input';
import Message from '~components/forms/elements/Message';
import P from '../typography/P';

const AddFacebook = () => {
  const [apiResponse, setApiResponse] = useState();
  const [apiError, setApiError] = useState(false);
  const [apiFail, setApiFail] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const [mail, setMail] = useState('');
  const [firstname, setFirstname] = useState('');

  const addLead = async () => {
    try {
      setApiResponse('');
      setApiError(false);
      setApiFail(false);
      setApiSuccess(false);
      let url = `/.netlify/functions/activeCampaignFooter`;
      let body = {
        mail,
        firstname
      };
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
      });

      if (response.status === 500) {
        const jsonData = await response.json();
        setApiResponse(jsonData[0].title);
        setApiError(true);
      } else if (response.status !== 200) {
        const jsonData = await response.json();
        setApiResponse('Etwas ist schief gelaufen. Versuche es später nochmal oder kontaktiere bitte uns direkt');
        setApiFail(true);
      } else {
        setApiResponse('Bitte bestätige deine Anmeldung in deinem Postfach. Bitte überprüfe auch deinen Spam-Ordner, falls du nichts erhalten hast.');
        setApiSuccess(true);

        navigate('/email-bestaetigung/');
      }

      //   setApiResponse(jsonData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    addLead();
  };

  return (
    <div>
      {apiSuccess ? (
        <Message text={apiResponse} svg={<CheckCircleIcon className="h-5 w-5 text-green-400" />} bgColor="bg-green-50" textColor="text-green-400" />
      ) : (
        <form className="space-y-4" onSubmit={handleChange}>
          <Input type="text" label="Name" id="name" placeholder="Max" autoComplete="given-name" onChange={(e) => setFirstname(e.target.value)} value={firstname} white />
          <Input type="email" label="E-Mail" id="email" placeholder="max@example.com" autoComplete="email" onChange={(e) => setMail(e.target.value)} value={mail} required white />
          <P textColor="text-grey-900">
            Mit der Anmeldung bestätigst Du die{' '}
            <Link to="/datenschutz/" className="underline">
              Datenschutzbestimmungen
            </Link>{' '}
            gelesen zu haben und dass Du damit einverstanden bist.
          </P>
          <Button text="Anmelden" submit />

          {apiError && <Message text={apiResponse} svg={<ExclamationCircleIcon className="h-5 w-5 text-yellow-400" />} bgColor="bg-yellow-50" textColor="text-yellow-400" />}

          {apiFail && <Message text={apiResponse} svg={<XCircleIcon className="h-5 w-5 text-red-400" />} bgColor="bg-red-50" textColor="text-red-400" />}
        </form>
      )}
    </div>
  );
};

export default AddFacebook;

import { ExternalLinkIcon } from '@heroicons/react/solid';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Container from '../common/Container';
import GridTwo from '../common/GridTwo';
import SvgAnchorFm from '../common/svgs/SvgAnchorFm';
import SvgFacebook from '../common/svgs/SvgFacebook';
import SvgInstagram from '../common/svgs/SvgInstagram';
import SvgYoutube from '../common/svgs/SvgYoutube';
import NewsletterFooter from '../newsletter/NewsletterFooter';
import H1 from '~components/typography/H2';
import H2 from '../typography/H2';
import Button from '~components/common/Button';
import P from '../typography/P';
import Divider from '~components/sections/Divider';

const Footer = ({ minimalFooter, dividerFooter }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              instagram
              facebook
              anchor
              youtube
            }
          }
        }
        newsletterImage: file(relativePath: { eq: "newsletter.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  );

  const menuItems = [
    {
      text: 'Impressum',
      link: '/impressum/',
      external: false
    },
    {
      text: 'Datenschutz',
      link: '/datenschutz/',
      external: false
    },
    {
      text: 'Haftungsausschluss',
      link: '/haftungsausschluss/',
      external: false
    },
    {
      text: 'AGB',
      link: '/agb/',
      external: false
    },
    {
      text: 'Impressum APP',
      link: '/app/rechtliches/impressum/',
      external: false
    },
    {
      text: 'Datenschutz APP',
      link: '/app/rechtliches/datenschutz/',
      external: false
    },
    {
      text: 'Haftungsausschluss APP',
      link: '/app/rechtliches/haftungsausschluss/',
      external: false
    },
    {
      text: 'AGB APP',
      link: '/app/rechtliches/agb/',
      external: false
    }
  ];

  const menuItemsHerausforderungen = [
    {
      text: 'Zähneknirschen',
      link: '/herausforderungen/zaehneknirschen/'
    },
    {
      text: 'Schlafkrankheit',
      link: '/herausforderungen/schlafkrankheit/'
    },
    {
      text: 'Bluthochdruck',
      link: '/herausforderungen/bluthochdruck/'
    },
    {
      text: 'Gedankenkreise',
      link: '/herausforderungen/gedankenkreise/'
    }
  ];

  const menuItemsKurse = [
    {
      text: 'Kursübersicht',
      link: '/kurse/'
    },
    {
      text: 'Autogenes Training',
      link: '/kurse/autogenes-training-onlinekurs/'
    },
    {
      text: 'Progressive Muskelentspannung',
      link: '/kurse/progressive-muskelentspannung-onlinekurs/'
    },
    {
      text: 'Stressmanagement ',
      link: '/kurse/stressmanagement-onlinekurs/'
    },
    {
      text: 'Kursquiz',
      link: '/kurse/kursquiz/'
    },
    {
      text: 'Krankenkassencheck',
      link: '/kurse/krankenkasse/'
    },
    {
      name: 'Entspannungszeit',
      link: '/entspannungszeit/'
    },
    {
      name: 'App',
      link: '/app/'
    }
  ];

  const menuItemsWissen = [
    {
      text: 'Podcast',
      link: '/podcast/'
    },
    {
      text: 'Blog',
      link: '/blog/'
    },
    {
      text: 'FAQ',
      link: '/faq/'
    }
  ];

  const menuItemsUeberUns = [
    {
      text: 'Über uns',
      link: '/ueber-uns/'
    },
    {
      text: 'Kontakt',
      link: '/kontakt/'
    },
    {
      text: 'Termin',
      link: '/termin/'
    },
    {
      text: 'Partner',
      link: '/partner/'
    }
  ];

  return (
    <footer className="">
      {/* <div className="bg-gray-100">
				<Container noMargin className="py-8 md:py-16">
					<LogoCloud />
				</Container>
			</div> */}
      {minimalFooter !== true && (
        <div className="bg-gray-800 text-white">
          <Container noMargin className="py-8 md:py-16">
            <GridTwo>
              <div>
                <span className="mb-3 inline-flex items-center rounded-full bg-brand-gray p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                  <span className="rounded-full bg-brand-green px-3 py-0.5 text-sm font-semibold leading-5 text-brand-gray">Sneakpeak ins Buch!</span>
                  <span className="ml-4 text-sm">Jetzt kostenfrei anmelden</span>
                </span>
                <H1 className="mb-5" as="p">
                  Der Kurzgeschichten Newsletter
                </H1>
                <P className="mb-5 text-base sm:text-xl lg:text-lg xl:text-xl" textColor="text-white">
                  Ein einziger erfolgreicher Werbeagentur-Chef steht kurz vor dem Burnout - erfahre in unserem Newsletter, wie Oliver trotz prosperierender DAX-Kunden ins Straucheln geriet und sichere dir wertvolle Präventionsstrategien für deinen eigenen Erfolgsweg.
                </P>
                <Button to="/newsletter/" text="Zum Newsletter anmelden" />
                {/* <NewsletterFooter /> */}
              </div>
              <div className="flex h-full items-center justify-center">
                <GatsbyImage image={getImage(data.newsletterImage)} alt="Newsletter Symbolbild" title="Newsletter Symbolbild" className="max-w-md" />
              </div>
            </GridTwo>
          </Container>
        </div>
      )}

      <div className="bg-gray-900 text-white">
        {dividerFooter ? <Divider className={`text-gray-50`} /> : null}
        <Container noMargin className="py-8 md:py-16">
          {minimalFooter !== true && (
            <>
              <div className="mb-16 text-center font-display text-lg">
                <a href="https://www.instagram.com/entspannungshelden/" target="_blank" rel="noopener noreferrer" className="hover:underline">
                  @entspannungshelden <br />
                  <span className="block text-2xl font-bold">Folge uns auf Instagram</span>
                </a>
              </div>
              <nav className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                <div className="flex flex-col space-y-2">
                  <Link to="/herausforderungen/" className="mb-3 font-display font-bold hover:underline">
                    Herausforderungen
                  </Link>
                  {menuItemsHerausforderungen.map((menuItem, i) => (
                    <Link key={`menuitemHeruasforderung-${i}`} to={menuItem.link} className="mb-3 hover:underline">
                      {menuItem.text}
                    </Link>
                  ))}
                </div>
                <div className="flex flex-col space-y-2">
                  <Link to="/kurse/" className="mb-3 font-display font-bold hover:underline">
                    Kurse
                  </Link>
                  {menuItemsKurse.map((menuItem, i) => (
                    <Link key={`menuitemKurse-${i}`} to={menuItem.link} className="mb-3 hover:underline">
                      {menuItem.text}
                    </Link>
                  ))}
                </div>
                <div className="flex flex-col space-y-2">
                  <Link to="/wissen/" className="mb-3 font-display font-bold hover:underline">
                    Wissen
                  </Link>
                  {menuItemsWissen.map((menuItem, i) => (
                    <Link key={`menuitemWissen-${i}`} to={menuItem.link} className="mb-3 hover:underline">
                      {menuItem.text}
                    </Link>
                  ))}
                </div>
                <div className="flex flex-col space-y-2">
                  <Link to="/unternehmen/" className="mb-3 font-display font-bold hover:underline">
                    Unternehmen
                  </Link>
                  {menuItemsUeberUns.map((menuItem, i) => (
                    <Link key={`menuitemUnternehmen-${i}`} to={menuItem.link} className="mb-3 hover:underline">
                      {menuItem.text}
                    </Link>
                  ))}
                </div>
              </nav>
            </>
          )}
          <nav className="mt-8 flex flex-wrap justify-center text-sm">
            {menuItems.map((link, i) => {
              if (link.external) {
                return (
                  <div className="px-5 py-2" key={`menuitemBottom-${i}`}>
                    <a href={link.link} className="hover:underline" target="_blank" rel="noreferrer">
                      {link.text} <ExternalLinkIcon className="inline-block h-4 w-4" />
                    </a>
                  </div>
                );
              }
              return (
                <div className="px-5 py-2" key={`menuitemBottom-${i}`}>
                  <Link to={link.link} className="hover:underline">
                    {link.text}
                  </Link>
                </div>
              );
            })}

            <div className="px-5 py-2">
              <button type="button" data-cc="show-preferencesModal">
                Cookie Einstellungen
              </button>
            </div>
          </nav>
          <div className="my-8 flex justify-center space-x-6">
            <a href={`${data.site.siteMetadata.social.instagram}`}>
              <span className="sr-only">Instagram Profil</span>
              <SvgInstagram className="h-6" />
            </a>
            <a href={`${data.site.siteMetadata.social.youtube}`}>
              <span className="sr-only">YouTube- Kanal</span>
              <SvgYoutube className="h-6" />
            </a>
            <a href={`${data.site.siteMetadata.social.facebook}`}>
              <span className="sr-only">Facebook Profil</span>
              <SvgFacebook className="h-6" />
            </a>
            <a href={`${data.site.siteMetadata.social.anchor}`}>
              <span className="sr-only">AnchorFM Profil</span>
              <SvgAnchorFm className="h-6" />
            </a>
          </div>
          <div className="text-center text-xs">
            Alle Preise werden inkl. USt. ausgewiesen.
            <br />© 2020-{new Date().getFullYear()} Entspannungshelden Johannes Förster
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import Helmet from 'react-helmet';

const GTag = () => {
  return (
    <Helmet>
      <script type="text/plain" data-category="marketing" async src="https://www.googletagmanager.com/gtag/js?id=G-2E0T118Z2N"></script>
      <script type="text/plain" data-category="marketing" async>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-2E0T118Z2N'), { 'anonymize_ip': true };
          `}
      </script>
    </Helmet>
  );
};

export default GTag;

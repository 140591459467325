import React from 'react';

const Message = ({ text, svg, bgColor, textColor }) => {
  return (
    <div className={`p-4 ${bgColor}`}>
      <div className="flex">
        <div className="flex-shrink-0">{svg}</div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${textColor}`}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Message;

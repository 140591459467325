import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

interface Props {
  text?: string;
  to?: string;
  href?: string;
  onClick?: React.MouseEventHandler;
  target?: string;
  rel?: string;
  submit?: boolean;
  type?: 'primary' | 'transparent' | 'white';
  download?: boolean;
  small?: boolean;
  children?: React.ReactNode;
  className?: string;
  anchor?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const buttonClassSetup = `inline-flex rounded-xl items-center justify-center border border-transparent text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition-colors duration-300`;
export const buttonClassPrimary = `bg-brand-green text-white border-brand-green-dark hover:text-white hover:bg-brand-green-dark shadow`;
export const buttonClassWhite = `bg-white border-gray-300 text-black hover:bg-gray-300 shadow`;
export const buttonClassTransparent = `bg-transparent border-transparent hover:underline`;
export const buttonClassDisabled = `cursor-not-allowed opacity-50`;
export const buttonClassBig = `px-5 py-3`;
export const buttonClassSmall = `px-5 py-1`;

const Button: React.FC<Props> = ({ text, to, href, download, onClick, target, rel, submit, type = 'primary', small, anchor, className, children, loading, disabled = false }) => {
  let classes = buttonClassSetup;

  switch (type) {
    case 'primary':
      classes += ` ${buttonClassPrimary} ${small ? buttonClassSmall : buttonClassBig} ${className || ''}`;
      break;
    case 'white':
      classes += ` ${buttonClassWhite} ${small ? buttonClassSmall : buttonClassBig} ${className || ''}`;
      break;
    case 'transparent':
      classes += ` ${buttonClassTransparent} ${small ? buttonClassSmall : buttonClassBig} ${className || ''}`;
      break;
  }

  if (disabled) {
    classes += ` ${buttonClassDisabled}`;
  }

  if (to) {
    return (
      <Link to={to} className={classes}>
        <ButtonContent text={text} loading={loading}>
          {children}
        </ButtonContent>
      </Link>
    );
  }

  if (anchor) {
    return (
      <AnchorLink to={anchor} title={text} className={classes}>
        <ButtonContent text={text} loading={loading}>
          {children}
        </ButtonContent>
      </AnchorLink>
    );
  }

  if (href) {
    return (
      <a href={disabled ? undefined : href} download={download} className={classes} target={target} rel={rel}>
        <ButtonContent text={text} loading={loading}>
          {children}
        </ButtonContent>
      </a>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick} type="button" className={classes} disabled={disabled}>
        <ButtonContent text={text} loading={loading}>
          {children}
        </ButtonContent>
      </button>
    );
  }

  if (submit) {
    return (
      <button type="submit" className={classes} disabled={disabled}>
        <ButtonContent text={text} loading={loading}>
          {children}
        </ButtonContent>
      </button>
    );
  }

  return (
    <button onClick={onClick} className={`bg-red-600 ${classes}`}>
      Missing props
    </button>
  );
};

export default Button;

interface ButtonContentProps {
  text?: string;
  loading?: boolean;
  children?: React.ReactNode;
}

const ButtonContent: React.FC<ButtonContentProps> = ({ loading, text, children }) => {
  return loading ? (
    <LoadingDots />
  ) : (
    <>
      {text}
      {children}
    </>
  );
};

interface LoadingDotsProps {
  dark?: boolean;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({ dark = false }) => {
  return (
    <div className="flex animate-pulse items-center justify-center space-x-1" style={{ minHeight: 24.5 }}>
      <div className={`h-2 w-2 rounded-full opacity-75 ${dark ? 'bg-black' : 'bg-white'}`} />
      <div className={`h-2 w-2 rounded-full opacity-75 ${dark ? 'bg-black' : 'bg-white'}`} />
      <div className={`h-2 w-2 rounded-full opacity-75 ${dark ? 'bg-black' : 'bg-white'}`} />
    </div>
  );
};

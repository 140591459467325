import { CookieConsentConfig } from 'vanilla-cookieconsent';

/**
 * @type {UserConfig}
 *
 * Find Doku here: https://github.com/orestbida/cookieconsent
 */
const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'cloud',
      position: 'bottom center'
    },
    preferencesModal: {
      layout: 'bar',
      position: 'right'
    }
  },
  autoClearCookies: true,
  mode: 'opt-in',
  disablePageInteraction: false,
  hideFromBots: true,
  revision: 0,
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true // this category cannot be disabled
    },
    analytics: {
      enabled: false,
      readOnly: false,
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/
          },
          {
            name: /^_hj/
          }
        ]
      }
    },
    marketing: {
      enabled: false,
      readOnly: false
    }
  },
  language: {
    default: 'de',
    translations: {
      de: {
        consentModal: {
          title: 'Cookie-Einstellungen',
          description:
            'Wir wollen auf unserer Webseite den bestmöglichen Service und Funktionen anbieten. Dies wird durch die Einbindung von sogenannten Cookies ermöglicht. <button type="button" data-cc="c-settings" class="cc-link">Mehr Infos</button>',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          showPreferencesBtn: 'Einstellungen',
          footer: `
              <a href="/datenschutz">Datenschutz</a>
              <a href="/impressum">Impressum</a>
          `
        },
        preferencesModal: {
          title: 'Cookie-Einstellungen',
          savePreferencesBtn: 'Einstellungen speichern',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Alle ablehnen',
          closeIconLabel: 'Schließen',
          sections: [
            {
              title: 'Cookie-Nutzung 📢',
              description:
                'Wir verwenden Cookies, um die grundlegenden Funktionen der Website sicherzustellen und Ihr Online-Erlebnis zu verbessern. Du kannst für jede Kategorie wählen, ob Sie sich jederzeit an- oder abmelden möchtest. Weitere Einzelheiten zu Cookies und anderen sensiblen Daten findenst Du in der vollständigen <a href="/datenschutz/" class="cc-link">Datenschutzerklärung</a>.'
            },
            {
              title: 'Unbedingt erforderliche Cookies',
              description: 'Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich. Ohne diese Cookies würde die Website nicht richtig funktionieren',
              linkedCategory: 'necessary'
            },
            {
              title: 'Leistungs- und Analyse-Cookies',
              description: 'Diese Cookies ermöglichen es der Website, sich an die Entscheidungen zu erinnern, die Sie in der Vergangenheit getroffen haben.',
              linkedCategory: 'analytics',
              cookieTable: {
                caption: 'Dienste',
                headers: {
                  name: 'Name',
                  desc: 'Beschreibung'
                },
                body: [
                  {
                    col1: 'Google Analytics',
                    col2: 'Sammeln von anonymisierten Besucherstatistiken zur Verbesserung von Inhalten sowie Aufbau unseres Online-Angebots. Die Daten werden für statistische und Marktforschungszwecke ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.'
                  }
                ]
              }
            },
            {
              title: 'Mehr Informationen',
              description: 'Bei Fragen zu unserer Richtlinie zu Cookies und Ihren Auswahlmöglichkeiten <a class="cc-link" href="/impressum/">kontaktiere uns bitte</a>.'
            }
          ]
        }
      }
    }
  }
};

export default pluginConfig;

import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import React, { Fragment } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function DropdownMenu({ title, active, links }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-brand-green-dark' : 'text-brand-gray',
              'focus:outline-none group inline-flex items-center font-medium hover:text-brand-green focus:ring-2 focus:ring-brand-green focus:ring-offset-2',
              active === title ? 'text-brand-green-dark' : 'text-brand-gray'
            )}
          >
            <span>{title}</span>
            <ChevronDownIcon className={classNames(open ? 'text-brand-green-dark' : 'text-brand-gray', 'ml-2 h-5 w-5 group-hover:text-brand-green')} aria-hidden="true" />
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel static className="absolute z-10 mt-3 -ml-4 w-screen max-w-md lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
              <div className="overflow-hidden ring-black ring-opacity-5">
                <div className="relative grid gap-6 rounded-xl border bg-white px-5 py-6 shadow-xl sm:gap-8 sm:p-8 lg:grid-cols-2">
                  {links.map((item, i) => (
                    <Link key={i} to={item.href} className="focus:outline-none -m-3 flex items-start rounded-xl p-3 hover:bg-gray-50/10 focus:ring-2 focus:ring-brand-green focus:ring-offset-2">
                      <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-xl bg-brand-green/90 text-white sm:h-12 sm:w-12">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div className="ml-4">
                        <p className={classNames('', active === item.name ? 'text-brand-green-dark' : 'text-brand-gray')}>{item.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default DropdownMenu;

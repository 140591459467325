import React from 'react';

interface Props {
  children: React.ReactNode;
  noMargin?: boolean;
  id?: string;
  backgroundColor?: string;
  className?: string;
  width?: 'narrow' | 'normal';
}

const Container: React.FC<Props> = ({ children, noMargin, id, backgroundColor, className, width = 'normal' }) => {
  const sectionClassInital = `mx-auto  ${width === 'normal' ? 'max-w-screen-2xl' : 'max-w-screen-lg'}  px-4 ${className ? className : ''}`;
  const sectionId = id ? id : undefined;

  if (backgroundColor !== undefined) {
    return (
      <ContainerWrapper backgroundColor={backgroundColor}>
        <section className={`${sectionClassInital} ${noMargin ? '' : 'py-8 md:py-16 lg:py-32'}`} id={sectionId}>
          {children}
        </section>
      </ContainerWrapper>
    );
  }
  return (
    <section className={`${sectionClassInital} ${noMargin ? '' : 'my-8 md:my-16 lg:my-32'}`} id={sectionId}>
      {children}
    </section>
  );
};

export default Container;

interface PropsWrapper {
  children: React.ReactNode;
  backgroundColor?: string;
}

const ContainerWrapper: React.FC<PropsWrapper> = ({ backgroundColor, children }) => {
  return <div className={backgroundColor}>{children}</div>;
};
